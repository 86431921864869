<template>
  <div id="shopcart_sign">
    <div class="hadheight"></div>
    <Header />
    <!-- 登录后的购物车 -->
    <div class="shopcart_sign-sec1 conorderbox-sec2">
        <div class="basecont">
            <div class="cont">
                <div class="lt">
                    <div class="shopcart_sign_nav">
                        <div class="f_lttit">
                            <div class="tit basefont30">
                                购物车
                            </div>
                            <span>{{shopfixed.length}}件商品</span>
                        </div>
                        <div class="f_gtsele">
                            <div class="name">
                                已选 {{lengthShop}} 件
                            </div>
                            <a href="javascript:;" @click="checkQuan()" :class="{check:true,on:qucheck}">
                                <i></i>
                                <span>全选</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shopcart_sign-sec2 conorderbox-sec2">
        <div class="basecont">
            <div class="cont">
                <div class="lt">
                    <div class="shopcart_sign_cont">
                        <div class="item" v-for="(item,index) in shopfixed" :key="index">
                            <div class="img">
                                <img :src="item.productImg" :alt="item.skuTitle">
                            </div>
                            <div class="font">
                                <div class="tit">
                                    <div class="name">
                                        {{item.skuTitle}}
                                    </div>
                                    <a href="javascript:;" class="f_but" @click="clearcart(item.skuId)">
                                        <img src="../../assets/images/removelajiiconimg1.png" alt="">
                                    </a>
                                </div>
                                <div class="text">{{item.text}}</div>
                                <div class="f_ne">
                                    <div class="fw_zen">
                                        <a href="javascript:;" @click="reduce(index)" class="f_jian">-</a>
                                        <input type="text"  @change="maxShop(index)" v-model="item.buyNum" >
                                        <a href="javascript:;" @click="add(index)"  class="f_jia">+</a>
                                    </div>
                                    <a href="javascript:;"  class="jia">
                                        ¥ {{item.price}}
                                    </a>
                                </div>
                            </div>
                            <a href="javascript:;" @click="xuanzhong(index)" :class="{check:true,on:item.check}">
                                <i></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="gt">
                    <div class="f_nei">
                        <div class="tit">订单详情</div>
                        <div class="zong">
                            <span>总计</span>
                            <div class="jia">¥ {{zongjia}}</div>
                        </div>
                        <a href="javascript:;" @click="subCon()" class="hm-but f_but">
                            <span>去结算</span>
                            <i>
                                <img src="../../assets/images/jiantouyo1.png" >
                                <img src="../../assets/images/jiantouyo2.png" >
                            </i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- 下载APP结算 享9折优惠 -->
    <transition name="fade">
        <div class="f_discount" v-show="discount">
            <div class="bag" @click="disHide()"></div>
            <div class="cont">
                <a href="javascript:;" @click="disHide()" class="close"><img src="../../assets/images/closeiconf1.png" alt=""></a>
                <div class="tit">
                    <p>下载APP结算</p>
                </div>
                <div class="text"><p>享<span>9折</span>优惠</p></div>
                <div class="img">
                    <img src="../../assets/images/imgericofa1.jpg" alt="">
                </div>
            </div>
        </div>
    </transition>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import * as math from "mathjs";
import { mapState, mapMutations, mapActions } from 'vuex';
export default {
  name: "Shoppingcart2",
  components: {
    Header,
    Footer
  },
  data(){
    return {
        qucheck: false, //全选
        discount: false,  //优惠弹窗
        zongjia: 0,
        xuanzkai: true,
        couterData: []
    }
  },
  created(){
    //   console.log(this.$route.params);
    //   if(this.$route.params.altdis){
    //       this.discount=this.$route.params.altdis
    //   }
  },
  mounted(){
    console.log(this.shopfixed);
  },
  computed:{
      ...mapState({
            shopfixed: state => state.Cart.cartarry
      }),
      lengthShop:function(){ //计算订单个数
          let i = 0; //选中的长度
          this.shopfixed.find((atr1)=>{
              if( atr1.check==true){
                  i++;
              }
          });
          return i;
      }
  },
  watch:{
    shopfixed: {
        // 执行方法
        handler(newValue,oldValue) {
                // var order = newValue;
                // for(var i=0;i<order.length;i++){
                //         if(!isNaN(parseFloat(order[i].buyNum)) && 
                //             isFinite(order[i].buyNum) && order[i].check){
                //         zong=math.evaluate(zong+" + ("+order[i].buyNum+" * "+order[i].price+")");	
                //     }
                // }
                const jud = newValue.findIndex(v => v.check);
                newValue.forEach(v => {
                    let leth = v.buyNum.length;
                    if(leth>8){
                        this.$message({
                            message: "最多输入八位数！",
                            type: 'error',
                        });
                        v.buyNum = Number(v.buyNum.substring(0,8));
                        return false;
                    }
                });
                if(jud!=-1){
                    let data = {
                        orderItemVOList: [],
                    };
                    newValue.forEach(v => {
                        if(v.check) {
                            data.orderItemVOList.push({
                                skuId: v.skuId,
                                buyNum: v.buyNum
                            });
                        }
                        let leth = v.buyNum.length;
                    });
                    this.$axios.post("/api2/portal/auth/order/detail/calculatePrice",data).then(res => {
                        if(res.code == "000000"){
                            this.xuanzkai=false;
                            this.zongjia = res.data.orderPrice;
                            this.couterData = res.data;
                            console.log(res);
                        }else{
                            this.xuanzkai=true;
                            this.$message({
                                message: res.message,
                                type: 'error',
                            });
                        }
                    }).catch(error =>{
                        console.log(error);
                    });
                    
                }
        },
        deep: true, // 深度监听
    }
  },
  methods: {
    ...mapActions(['getCart','increaseCart','reduceCart','clearcart','editCart']),
    ...mapMutations(['setCart']),
    disShow(){
        $(".f_discount").fadeIn();
    },
    disHide(){
        $(".f_discount").fadeOut();
    },
    xuanzhong(index){ //单选
        this.shopfixed[index].check=!this.shopfixed[index].check;
        let i = 0; //选中的长度
        let di =  this.shopfixed.length; //订单长度
        this.shopfixed.find((atr1)=>{
            if(atr1.check==true){
                i++;
            }
        });
        if(i==di){
            this.qucheck=true;
        }else{
            this.qucheck=false;
        }
       
    },
    checkQuan(){  //全选
        if(this.qucheck){
            this.shopfixed.find((atr1)=>{
            if(atr1.check==true){
                atr1.check=false;
            }
        });
        }else{
            this.shopfixed.find((atr1)=>{
                if(atr1.check==false){
                    atr1.check=true;
                }
            });
        }
        this.qucheck=!this.qucheck;
        
    },
    // danzong:function(liang,jia){
    //     return math.evaluate(liang+"*"+jia);
    // },
    removeShopFixed:function(_index,ite){ //购物车删除
        this.shopfixed.splice(_index,1);
    },
    add(index){ //购物车加
        this.increaseCart({skuId:this.shopfixed[index].skuId,buyNum: 1});
    },
    reduce(index){ //购物车减
        this.reduceCart({skuId:this.shopfixed[index].skuId,buyNum:this.shopfixed[index].buyNum});
    },
    maxShop(index){ //购物车最大值
        let leth = this.shopfixed[index].buyNum.length;
        
        var params = {
            skuId:this.shopfixed[index].skuId,
            buyNum: Number(this.shopfixed[index].buyNum)
		}
        
		this.editCart(params);

        // if(this.shopfixed[index].liang>=Number(this.shopfixed[index].max)){
        //     this.shopfixed[index].liang=this.shopfixed[index].max;
        //     this.$message('该产品库存仅剩下'+this.shopfixed[index].max);
        // }
    },
    subCon(){
        if(this.xuanzkai){
            this.$message("请取消选中的非订单产品！");
            return false;
        }
        if(this.lengthShop==0){
            this.$message("请选中购物车的订单！");
            return false;
        }
        let data = {
            orderStatus: 100,
            orderItemInfoList: [],
            orderPrice: this.couterData.orderPrice,
            couponPrice: 0,
            exciseTax: this.couterData.exciseTax,
            actualPrice: this.couterData.realPayAmount
        };
        this.shopfixed.forEach(v => {
            if(v.check){
                data.orderItemInfoList.push({
                    skuName: v.skuTitle,
                    skuDesc: v.skuSubtitle,
                    buyNum: v.buyNum,
                    skuDefaultImg: v.productImg,
                    skuId: v.skuId,
                    skuDeposit: v.price,
                    
                });
            }
        });
        this.setLocalStorageData("orderMessage",data);
        this.$router.push("Orderdet");
    },
    getLocalStorageData(name){
        let data = localStorage.getItem(name);
        return data;
    },
    setLocalStorageData(name,obj){
        let data = typeof obj == 'string'?obj:JSON.stringify(obj);
        localStorage.setItem(name,data);
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>